import React from "react"
import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import DesktopHeader from "./DesktopHeader"
import MobileHeader from "./MobileHeader"

import "../styles/components/menu.scss"

const variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeOut",
      duration: 1.2,
    },
  },
  closed: {
    opacity: 0,
    y: "60px",
    transition: { duration: 0.1 },
  },
}

export const Menu = ({ current = "home" }) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0,
    // rootMargin: '500px 0px',
  })

  const style = { opacity: 0, y: 60 }
  const homeStyle = { position: "fixed", zIndex: 10 }

  return (
    <div id="citizen-navigation" ref={ref}>
      <motion.div
        className="desktop-navigation"
        style={current === "home" ? { ...style, ...homeStyle } : { ...style }}
        animate={inView ? "open" : "closed"}
        variants={variants}
      >
        <DesktopHeader current={current} />
      </motion.div>
      <motion.div
        className="mobile-navigation"
        style={current === "home" ? { ...style, ...homeStyle } : { ...style }}
        animate={inView ? "open" : "closed"}
        variants={variants}
      >
        <MobileHeader current={current} />
      </motion.div>
    </div>
  )
}

Menu.propTypes = {
  current: PropTypes.string,
}

export default Menu
